import "core-js/modules/es.array.push.js";
import axios from 'axios';
import { Grid, GridItem, Tag, Cell, CellGroup, Button, Image as VanImage, Icon, Overlay, Empty, Toast, showToast, showLoadingToast, showImagePreview } from 'vant';
export default {
  data() {
    return {
      inviteMoney: 0,
      inviteMoney1: 0,
      inviteMoney2: 0,
      inviteMoney3: 0,
      inviteUser: 0,
      inviteList: [],
      showlogin: false,
      realUser: 0,
      ckaCount: 0,
      showlist: true,
      inviteList: [],
      inviteUserStatus: 1,
      loginstatus: false
    };
  },
  methods: {
    makeInvite() {
      showLoadingToast('正在生成');
      axios.post('https://sct.qaqwq.com/api/xcx/getQrCode', {}, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code == 201) {
          showToast("获取海报成功！");
          showImagePreview([res.data.url]);
        }
      });
    },
    gotologin() {
      this.$router.push('/login');
    }
  },
  mounted() {
    //获取邀请总额
    axios.get('https://sct.qaqwq.com/api/xcx/getInviteDetail', {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(res => {
      if (res.data.code == 200) {
        this.inviteMoney = res.data.data.pickUpMoney, this.inviteUser = res.data.data.inviteUserTotal, this.realUser = res.data.data.realUser, this.ckaCount = res.data.data.ckaCount;
      } else {
        this.loginstatus = true;
      }
    });
    //获取邀请规则
    axios.get('https://sct.qaqwq.com/api/xcx/inviteinfo', {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(res => {
      this.inviteMoney1 = parseInt(res.data.inviteMoney), this.inviteMoney2 = parseInt(res.data.inviteMoney2nd), this.inviteMoney3 = parseInt(res.data.inviteMoney3nd), this.inviteUserStatus = res.data.inviteUserStatus;
    });
    //邀请用户的列表
    axios.get('https://sct.qaqwq.com/api/xcx/getInviteUserDetail', {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(res => {
      if (res.data.length > 0) {
        this.showlist = false, this.inviteList = res.data;
      }
    });
  }
};